:root {
    --color-primary: #19191d;
    --color-accent: rgba(255, 255, 255, 0.24);
    --color-black: black;
    --color-title: #5666e7;
    --color-background: #e0f7fa;
    --color-outline: #1d3557;
    --color-highlight: #06d6a0;
    --color-headings: #f7f8f8;
    --color-secondaryFont: #ffffffb8;
    --color-instructions: #8b8c9b;
    --color-danger: #ed4e50;
    --color-lightblue: #f4f6ff;
    --color-alternative: #4841413d;
  }

  body {
      background: var(--color-primary);
  }


  html {
    font-size: 62.5%;
  }
  
  body {
    font-size: 1.6 * 10px;
    line-height: 1.5;
  }
  
  h1,
  h2,
  h3,
  p {
    font-family: Inter, Arial, Helvetica, sans-serif;
    color: var(--color-headings);
    margin-bottom: 1.2 * 10px;
    line-height: 1.1;
  }
  
  .h1 {
    font-size: 3.6 * 10px;
    font-weight: 700;
  }
  
  .h2 {
    font-size: 2.6 * 10px;
  }
  
  .h3 {
    font-size: 2 * 10px;
  }
  
  p {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 25px;
    color: var(--color-secondaryFont);
  }

  em {
    color: var(--color-highlight);
    font-weight: 600;
    font-style: normal;
  }

/*-----------Links---------------------------*/

a {
    text-decoration: none;
  }
  
  .link-style {
    color: var(--color-blue);
    font-style: normal;
  }
  
  /*---------button---------------------*/
  .btnn {
    line-height: 3.6 * 10px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 4px;
    border: 0;
    display: inline-block;
    text-decoration: none;
    padding-top: 0.3 * 10px;
    padding-bottom: 0.3 * 10px;
    margin: 1 * 10px 0;
    width: 230px;
    margin-top: 2 * 10px;
    margin-bottom: 2 * 10px;
    cursor: pointer;
    font-size: 14px;
  }

  .btnn-text {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .btnn-primary {
    background: #5567e7;
    color: var(--color-headings);
  }
  
  
  
  .btnn-secondary {
    background: #ffffff3d;
    border: 1px solid var(--color-outline);
    // padding: 12px;
    margin: 10px;
  }
  
  .btnn-disable {
    background: #5567e7;
    color: var(--color-headings);
    opacity: 0.65;
    cursor: not-allowed;
  }
  
  .btnn-strech {
    width: 26 * 10px;
  }
  
  .floatbutton{
    // color: var(--color-title);
    position: fixed;
    bottom:30px;
    right:10%;
    background: var(--color-title);
    outline: 0;
    border-radius: 10px;
    padding: 10px 20px;
    border: 0px;
    cursor: pointer;
  }



  @media screen and (min-width: 768px) {
    .btnn-strech {
      width: 40 * 10px;
    }
  }

/*----------inputs--------------------*/

.label-style {
    font-size: 1.6 * 10px;
    color: var(--color-primary);
    line-height: 2.4 * 10px;
}

.label-style-light {
    color: var(--color-primary);
}

.label-style-dark {
    font-size: medium;
    color: var(--color-headings);
    padding: 1 * 10px;
}

.input--custom {
    border: 2px solid #cfd7fd;
    padding: 2 * 10px;
    display: block;
    height: 4.5 * 10px;
    width: 26 * 10px;
    border-radius: 7px;
    margin: 0;
}
.input--custom:focus {
    border-color: var(--color-blue);
}

.input-bundle {
    margin-top: 1.6 * 10px;
    margin-bottom: 2.6 * 10px;
}

.input--custom::placeholder {
    border-color: var(--color-highlighttext);
    font-size: medium;
}

.input--dark {
    margin-bottom: 1 * 10px;
    padding: 1 * 10px;
    border: 1px solid var(--color-accent);
    background: var(--color-alternative);
    color: var(--color-headings);
    outline: 0;
}

.input--dark:focus {
    border-color: var(--color-blue);
}

.input--dark::placeholder {
    border-color: var(--color-highlighttext);
    font-size: medium;
}

@media screen and (min-width: 768px) {
    .input--custom {
        width: 40 * 10px;
    }

    .input-daterange {
        padding: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .input--custom {
        width: 40 * 10px;
    }
}

/*------------- Alert box -------------------------*/

.alertmessage {
    color: var(--color-danger);
    font-size: 1.5 * 10px;
    font-weight: 500;
    padding: 0.5 * 10px;
    padding-bottom: 2.5 * 10px;
}




  /* ------------ Container ---------------------------*/

  .containerbox {
  background-color: var(--color-alternative);
  width: 100%;
  max-width: 600px;
  padding: 100px 50px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px;
  justify-content: space-around;
}


.tableStyle{
  margin: 20px;
  color:var(--color-headings);
  font-size: 20px;
  border: 2;
}

.tableStyle tr {
  border: 5px solid var(--color-alternative);
}

.tableStyle td {
padding: 20px;
}

th, td {
  border-right: 5px solid var(--color-alternative);
}

th:last-child, td:last-child {
  border-right: none;
}


/*--------- Images -----------------------------*/
.profileImage{
  width: 50px;
  
}


/*--------- RoundContainer -----------------------------*/
.roundContainer{
  width: 30px;
  padding: 10px;
  color: white;
  height: 15px;
  opacity: 0.4;
}

.roundContainer--red{
  background: var( --color-danger);
  color: black;
  font-weight: 800;
}

.roundContainer--green {
  background: var( --color-highlight);
  color: black;
  font-weight: 800;

}

.roundContainer--yellow {
  background: rgb(226, 226, 111);
  color: black;
  font-weight: 800;

}


/*--------- Hide ------------------------------------*/

.hide{
  display: none;
}

/*-------- Close ---------------------------------*/

.closeButton {
  width: 100px;
  position: absolute;
  top:"5%";
  right: 10%

}

.rectangleBox{
  color: white;
  background: var(--color-alternative);
  padding: 10px;
  margin: 10px;
}

.opacity01{
  opacity: 0.1;
}


//*---------- Button color --------------------*//

.outlinebutton {
  background-color: white;
  border-radius: 25px;
  padding: 5px 20px;
  outline: 0;
  outline: none;
  border: 0px;
  font-weight: 600;
  
}

.outlinebutton--red{
  background: rgb(248, 171, 175);;
  color: rgb(160 75 53);
  font-weight: 600;
}

.outlinebutton--grey{
  border: 0.8px solid rgb(95 111 111);
  color: rgb(95 111 111);
  background: aliceblue;
}


.outlinebutton--green{
  background: rgb(210, 244, 211);
  color:  rgb(27, 93, 74);

}

.outlinebutton--blue{
  border: 0.5px solid rgb(96, 117, 198);
  color: rgb(113, 127, 200);
  cursor: pointer;
  font-weight: 700;
}

.outlinebutton--blue--selection {
  color: white;
  border: 0.5px solid rgb(96, 117, 198);
  background: rgb(96, 117, 198);
  cursor: pointer;
}

.outlinebutton--blue--cursor {
  cursor: pointer;
}
