  /* ------------ Container ---------------------------*/

  .containerbox {
    background-color: var(--color-alternative);
    width: 100%;
    max-width: 600px;
    padding: 100px 50px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px;
    justify-content: space-around;
  }

  
.profileHeader{
width:28%;
background-color: var(--color-black);
height: 100%;
position: absolute;
right: 0;
display: block;
overflow: auto;
}
  
