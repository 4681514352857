.ant-input-group .ant-input {
    background: black;
    color: white;
    outline: 0;
    border: 0;
}

.ant-input-number-input {
    background: black;
    color: white;
    outline: 0;
    border: 0;

}

.ant-select-single{
    background: black;
    color: white;
    outline: 0;
    border: 0;

}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {

    outline: 0;
    border: 0;
    background-color: #000;
}

.ant-input-number {
    outline: 0;
    border: 0;
}